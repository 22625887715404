import PendingDeliveries from '../views/PendingDeliveries';
import ReceivingReport from '../views/ReceivingReport';

export const redirectPath = '/delivery'; // redirect path if successfully logged in
export const API = process.env.REACT_APP_API_URL; // api link

// https://ant.design/components/icon/ for icons
export const routes = [ // array for routes
  {
    component: PendingDeliveries,
    title: 'Purchase Order',
    icon: 'list-ul',
    view: true, // change to false if you dont want this route to be on sidebar menu
    path: '/delivery',
  },
  {
    component: ReceivingReport,
    title: 'Receiving Reports',
    icon: 'list-ul',
    view: true, // change to false if you dont want this route to be on sidebar menu
    path: '/rr',
  },
]
