import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { redirectPath } from '../../config/config';

function Title({ title }) {
  const { pathname } = useLocation();
  return (
    <div style={{ width: '100%', borderBottom: '1px solid #1890ff', paddingBottom: 8 }}>
      <Link
        to={redirectPath}
        style={{ marginRight: 4, fontSize: 15, textTransform: 'uppercase' }}
      >
        Dashboard &gt;
      </Link>
      <Link to={pathname} style={{ textTransform: 'uppercase' }}>{title}</Link>
    </div>
  )
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
}
export default Title
